import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction003 from './Introduction003'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0032 = () => (
  <Wrapper>
    <ColumnH1 label="休憩時間のルールとは？労働基準法の規定やトラブル事例を紹介" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.08.10</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        労働者から一定時間以上労務の提供を受けるにあたっては、健康への配慮や労働生産性の向上のためにも労働時間の途中に休憩を設けなければなりません。休憩についても
        <span>労働基準法上明確に規定</span>
        があり、それを履行できていないためにトラブルに発展しているケースもあります。
        <br />
        <br />
        今回は、休憩時間について取得に関するルールやトラブル事例を交えて解説していきます。
      </p>
      <img src="/images/column/details/c0032.jpg" alt="キンクラコラム画像32" />
      <h1>法律上の休憩時間とは</h1>
      <p>
        <span>労働基準法第34条</span>
        に規定があり、労働時間が6時間までは休憩を与えなくても違法とはなりません。しかし、6時間を超えて8時間まで働かせる場合は少なくとも
        <span>45分</span>
        、8時間を超えて働かせる場合は少なくとも<span>1時間</span>
        与えなければ違法となります。
      </p>
      <h1>トラブル事例</h1>
      <p>
        「少なくとも」とあるように、これは最低基準であることから例えば労働時間が7時間の会社で、便宜上1時間の休憩を付与することは何ら問題がありません。実務上、トラブルになる点で休憩は労働時間の途中に与えることが求められています。
        <br />
        しかし、忙しさに感けて、途中に休憩が取れず、終業時刻と密接した時間に休憩を取らせそのまま帰宅させるという労務管理体制を敷く業種もありますが、
        <span>休憩の本来の目的を逸脱</span>しており問題です。
        <br />
        そもそも途中に休憩を設ける目的としては、
        <span>疲労による事故の防止や休憩後の労働生産性の向上</span>
        が目的です。恒常的に適切な休憩が取得できない状態は事故発生のリスクが高まっており、早急な是正が求められます。
        <br />
        <br />
        また、雇用契約上の労働時間が６時間のケースでは、労働時間が6時間を超えていないため、休憩を与えなくても違法ではありませんが、理論上1分でも時間外労働が発生してしまえば「6時間を超える」にあたることから
        <span>「労働時間の途中に」</span>
        休憩を与えなければなりません。この点、労働者の方から指摘され、問題が顕在化することがありますが、職種的に労働時間が6時間以内の雇用契約であっても時間外労働が予想される場合、労務リスクの観点から予め休憩時間を盛り込んだ雇用契約を締結しておくことが適切です。
        <br />
        しかし、子供の養育等で、労働者側から「拘束時間」自体を短くしてほしいために休憩時間を盛り込んだ雇用契約を嫌がるケースもあります。そのような場合は6時間を超えて働くことがないよう徹底することや、労働時間を再検討する必要があります。
        <br />
        労働者の意向を汲んだ上での雇用契約締結が望ましいことは言うまでもありません。しかし、一歩間違えば違法な労務管理となる雇用契約は避けておくべきです。
      </p>
      <Introduction003 />
      <h1>休憩に関するルール</h1>
      <p>
        休憩に関してはトラブル事例でも示したように、良かれと思ってやっていることが違法な労務管理になってしまうことがあります。そこで、他のルールについても確認しておきましょう。休憩は自由利用の原則と言い、警察官などの一部の業種を除き、
        <span>休憩時間内は自由に利用</span>
        させなければなりません。しかし、自由と言っても、職場の規律保持上、必要な制限（例えば会社の食堂内でビラ配布を禁ずる）を加えることは問題ありません。
        <br />
        <br />
        次に、病院等の一部の業種を除き、
        <span>休憩は一斉に与えなければなりません。</span>
        実務上この部分は認識されていないことが多い印象です。
        <br />
        休憩をしながら横で作業を行っているような状態では休憩中の労働者が休憩中であるにも関わらず気疲れしてしまい、休憩の本来の目的を達成できないためとされています。しかし、恒常的に休憩時間とする時間帯に電話が鳴る企業では労使協定を締結することで、班ごとなど、別々に休憩を与えることが可能となります。尚、本労使協定は所轄労働基準監督署へ届け出る必要はありません。
        <br />
        <br />
        休憩時間は労務を提供する時間ではないことから、賃金は発生しません。これは
        <span>「ノーワークノーペイの原則」</span>
        により、賃金は働いた時間にのみ支払うことを意味しており、会社に拘束されている時間全てが賃金支払い対象となるわけではありません。
        <br />
        <br />
        また、休憩中の外出を許可制にすることは問題ないかとの議論がありますが、結論としては問題ありません。ただし、自由利用を制限するような許可制となると問題ですので、注意が必要です。
        <br />
        <br />
        他のルールとして、休憩中に昼食をとりながら、電話が鳴った際には暗黙の了解で対応を求めるという企業も散見されますが、これは法で想定する休憩とはなりません。
        <br />
        <span>休憩は労働からの解放を保障された時間</span>
        でなければならず、万が一電話が鳴った際に、対応することが求められる（または期待されている）状態は労働からの解放が保障されているとは言えませんので、休憩ではありません。もちろん結果的に電話が鳴らなかったとしてもそれは結果論であり、労働からの解放が保障された状態ではありません。
      </p>
      <h1>最後に</h1>
      <p>
        休憩は集中力欠如にともなう事故の未然防止や労働者への健康配慮など、様々な目的があります。また、法で定める時間を付与していても休憩と並行して電話当番を担っている状態では休憩にあたらないことから、
        <span>労使協定を締結し、時間帯をずらして休憩を取得</span>
        させるなどの再考が求められます。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0032

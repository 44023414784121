import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0032 from '../../../components/molecules/columnDetailInner0032'
import UsefulFunctionLayout002 from '../../../components/molecules/usefulFunctionLayout002'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0032 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '労働基準法における休憩時間のルールを解説！',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="休憩時間のルールとは？労働基準法の規定やトラブル事例を紹介"
        description="休憩は6時間を超えて8時間まで働かせる場合は45分、8時間を超えて働かせる場合は1時間必要です。休憩のルールやトラブル事例を把握しておきましょう。"
        ogUrl="https://kintaicloud.jp/column/details/C0032/"
        ogType="article"
        ogTitle="休憩時間のルールとは？労働基準法の規定やトラブル事例を紹介"
        ogDescription="休憩は6時間を超えて8時間まで働かせる場合は45分、8時間を超えて働かせる場合は1時間必要です。休憩のルールやトラブル事例を把握しておきましょう。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0032.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0032 />
        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout002 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0032

export const pageQuery = graphql`
  query C0032 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
